<template>

     <!-- Cart dropdown-->
                <div class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
                  <div class="widget widget-cart px-3 pt-2 pb-3">
                    <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false" v-if="countCart>0">

                      <div class="widget-cart-item pb-2 border-bottom" v-for="(item, index) in carts" :key="'cart'+index" >
                        <button @click="removeCart(item)" class="close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">&times;</span></button>
                        <div class="media align-items-center">
                          <router-link class="d-block mr-2" v-bind:to="'/product/detail/'+item.id+'/'+item.slug"><img width="64" :src="item.photo" alt="Product"/></router-link>
                          <div class="media-body">
                            <h6 class="widget-product-title"><router-link v-bind:to="'/product/detail/'+item.id+'/'+item.slug" >{{ item.name }}</router-link></h6>
                            <div class="widget-product-meta"><span class="text-accent mr-2">Rp {{ item.price.toLocaleString('id-ID') }}</span><span class="text-muted">x {{ item.quantity }}</span></div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div style="height: 5rem;" data-simplebar data-simplebar-auto-hide="false" v-else>
                        <div class="media align-items-center">
                          <div class="alert alert-warning d-block w-100" role="alert">
                            Shopping cart empty!
                          </div>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                      <div class="font-size-sm mr-2 py-2"><span class="text-muted">Total Price ( {{countCart}} items )</span><br>
                      <span class="text-accent font-size-base ml-1">
                        Rp {{ totalPrice.toLocaleString('id-ID') }}</span></div></div><router-link class="btn btn-primary btn-sm btn-block" to="/cart"><i class="czi-card mr-2 font-size-base align-middle"></i>Checkout</router-link>
                  </div>

                </div>
                <!-- Cart dropdown-->
                <!-- <div class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
                  <div class="widget widget-cart px-3 pt-2 pb-3">
                    <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false" v-if="countCart>0">
                      <div class="widget-cart-item pb-2 border-bottom" v-for="(item, index) in carts" :key="'cart'+index">
                        <button @click="removeCart(item)" class="close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">&times;</span></button>
                        <div class="media align-items-center"><a class="d-block mr-2" href="shop-single-v1.html"><img width="64" src="img/shop/gems/gem1.jpg" alt="Product"/></a>
                          <div class="media-body">
                            <h6 class="widget-product-title"><a href="shop-single-v1.html">{{ item.name }}</a></h6>
                            <div class="widget-product-meta"><span class="text-accent mr-2">Rp {{ item.price.toLocaleString('id-ID') }}</span><span class="text-muted">x {{ item.quantity }}</span></div>
                          </div>
                        </div>
                      </div>
                      
                      
                    </div>
                    <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                      <div class="font-size-sm mr-2 py-2"><span class="text-muted">Total Price ( {{countCart}} items )</span><br><span class="text-accent font-size-base ml-1">Rp {{ totalPrice.toLocaleString('id-ID') }}</span></div><a class="btn btn-outline-secondary btn-sm" href="shop-cart.html">Expand cart<i class="czi-arrow-right ml-1 mr-n1"></i></a>
                    </div><a class="btn btn-primary btn-sm btn-block" href="checkout-details.html"><i class="czi-card mr-2 font-size-base align-middle"></i>Checkout</a>
                  </div>
                </div> -->
       
</template>

<script>  
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Cart',
  //    data: () => ({
  //   item: {}, // objek book
  // }),
    computed: {
        ...mapGetters({
          carts   : 'cart/carts',
          countCart     : 'cart/count',
          totalPrice    : 'cart/totalPrice',
          totalQuantity : 'cart/totalQuantity',
          totalWeight   : 'cart/totalWeight',
    }),
    },
    methods: {
        ...mapActions({
          setAlert    : 'alert/set',
          addCart     : 'cart/add',
          removeCart  : 'cart/remove',
          setCart     : 'cart/set',
        }),
        checkout(){
            this.close()
            this.$router.push({path: "/checkout"})
        },
        close() {
            this.$emit('closed', false)
        }
    },
    
}
</script>